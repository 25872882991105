<template>
  <div>
    <PageHeader text="Страница создания пользователя" />
    <UserForm />
  </div>
</template>

<script>
import UserForm from '@/components/forms/UserForm.vue'
import PageHeader from '@/components/common/PageHeader.vue'

export default {
  components: {
    UserForm,
    PageHeader,
  },
}
</script>
