<template>
  <v-form @submit.prevent="onSubmit" ref="form">
    <v-text-field
      v-if="!initialValues"
      v-model="formData.login"
      label="Логин"
      :rules="[
        rules.required,
        rules.noBeginSpaces,
        rules.noEndSpaces,
        rules.noSpaces,
      ]"
      validate-on-blur
    ></v-text-field>
    <v-text-field
      v-model="formData.fio"
      label="ФИО пользователя"
      :rules="[rules.required]"
      validate-on-blur
    ></v-text-field>
    <v-text-field
      v-model="formData.email"
      label="Email (не обязательно)"
      :rules="[rules.email]"
      validate-on-blur
    ></v-text-field>
    <v-text-field
      v-model="formData.phone"
      label="Телефон (не обязательно)"
    ></v-text-field>
    <v-select
      label="Роль"
      :rules="[rules.required]"
      :items="roles"
      v-model="formData.role_id"
      item-text="display"
      item-value="id"
    ></v-select>
    <v-select
      class="mb-8"
      label="Профиль"
      :items="profiles"
      v-model="formData.profiles"
      item-text="name"
      item-value="id"
      multiple
      no-data-text="В систему еще не добавили профили"
    ></v-select>
    <FormButtons />
  </v-form>
</template>

<script>
import FormButtons from '@/components/buttons/FormButtons.vue'

import { mapGetters, mapActions } from 'vuex'

import { addUser, editUser } from '@/lib/requestsAPI'

import { routeParams } from '@/mixins/routeParams'
import { formMixin } from '@/mixins/formMixin'
import { sendFormWithToastAndRedirect } from '@/mixins/sendFormWithToastAndRedirect'

import { EMAIL_PATTERN } from '@/store/const/emailPattern'
import { USER_ROLES } from '@/store/const/userRoles'
import { USERS, ADMIN, USER_SHORT } from '@/store/const/path'

export default {
  mixins: [formMixin, routeParams, sendFormWithToastAndRedirect],
  components: {
    FormButtons,
  },
  data() {
    return {
      formData: {},
      roles: Object.values(USER_ROLES).filter(r => r.creatableByAdmin),
      TEMPLATE_FORM_DATA: {
        login: '',
        fio: '',
        email: null,
        phone: '',
        role_id: null,
        profiles: [],
      },
      rules: {
        required: v => !!v || 'Обязательное поле',
        // здесь email не обязательный. Проверяем валидность только если введен
        email: v => !v || EMAIL_PATTERN.test(v) || 'Неправильный адрес почты.',
        noBeginSpaces: v =>
          !/^\s/.test(v) ||
          'Логин не должен содержать пробелы. (Пробел в начале строки)',
        noEndSpaces: v =>
          !/\s$/.test(v) ||
          'Логин не должен содержать пробелы. (Пробел в конце строки)',
        noSpaces: v => !/\s/.test(v) || 'Логин не должен содержать пробелы',
      },
    }
  },
  computed: {
    ...mapGetters({
      profiles: 'getProfilesNew',
    }),
    successPath() {
      return `/${ADMIN}/${USERS}/${USER_SHORT}-`
    },
  },
  methods: {
    ...mapActions(['loadProfiles']),
    prepareDefaultValues() {
      this.loadProfiles()
    },
    prepareSendData() {
      const result = { ...this.formData }
      if (this.initialValues) delete result.login
      if (result.email === '') result.email = null
      if (result.phone === '') result.phone = null
      if (result.profiles.length === 0) return result
      if (typeof result.profiles[0] === 'number') return result
      result.profiles = result.profiles.map(p => p.id)
      return result
    },
    add() {
      this.sendFormWithToastAndRedirect({
        action: addUser,
        data: this.prepareSendData(),
        successPath: this.successPath,
        updateSuccessPath: true,
        successMessage: `${this.formData.fio} добавлен`,
        errorMessage: 'Что-то пошло не так с добавлением пользователя',
      })
    },
    edit() {
      this.sendFormWithToastAndRedirect({
        action: editUser,
        data: { id: this.userId, data: this.prepareSendData() },
        successPath: `${this.successPath}${this.userId}`,
        updateSuccessPath: false,
        successMessage: `${this.formData.fio} отредактирован`,
        errorMessage: `Что-то пошло не так с редактированием пользователя ${this.userId}`,
      })
    },
  },
}
</script>
